import React, { useState, useEffect } from "react";
import { Fab } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowDropUp";

const GoTop = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <Fab onClick={scrollUp} className="go-up">
          <ArrowBack />
        </Fab>
      )}
    </>
  );
};

export default GoTop;