import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        homeTitle: "Home",
        moulinText:
          "In 2016. I have won in competition of famous TV Show called VICE. The prize was o fulfill my dream – and it was to go to Paris and dance side by side with Moulin Rouge dancers. This was one in lifetime experience, which expended my sights and give me a courage to dream bigger. I spent one whole day with Moulin Rouge dancers, participated in their rehearsal learned famous Can – Can choreography.",
        moulinTitle: "Full Show ",
        galleryTitle: "Gallery ", //MOULIN END
        musicTitle: "Music", //MUSIC END
        snTeamTitle: "Serbian National Team ",
        snText: "Choreographer and dancer", //SNT END
        musicalText:
          "In 2021. I was Choreographer of musical „The Lion King“ in Bi Village,Fažana (Croatia), also a main role (Nala).", //MUSICAL END
        kkczText:
          "Choreographer, cheerleader and team captain of Red Passion Fly, the official cheerleading group of Basketball Club Crvena Zvezda in Belgrade 2014-2021.",
        kkczTitle: "Red Star Basketball Club", //KKCZ END
        fitnessTitle: "Fitness Gallery",

        f4Title: "Euroleague",
        f4Text:
          "2019.Red Passion Fly was the winner of EFES dance challenge. My team and I performed in F4 Euroleague (Spain), and I was choreographer and dancer. Here are my favorite choreographies", //F4 END

        contactTitle: "Contact",
        contactText:
          "We're happy to answer any questions you have or provide you with an estimate. Just send us a message in the form below with any questions you may have.",
        labelName: "Your name (required)",
        labelEmail: "Your email (required)",
        labelSubject: "Subject",
        labelMessage: "Your message (required)",
        contactBtn: "Send",
        social: "Social network:", //Contact END
        biographyTitle: "Biography",
        biographySubtitle: "PROFESSIONAL DANCER & CHOREOGRAPHER",
        biographyAboutTitle: "About Me:",
        biographyText: `I have been dancing for over 20 years, and professionally as a dancer and coach for over 10 years. I graduated from the Faculty of Sports and Physical Education, majoring in sports dance. <br><br> I also do scientific research on the impact of applying biomechanical principles and methods to dance training, which makes me a pioneer in this field in Serbia. I wrote <a class="bioLink"  target="_blank" rel="noreferrer" href="https://phaidrabg.bg.ac.rs/view/o:26639">"Biomechanical analysis of basic ballet elements in the function of technique improvement and injury prevention".</a> and a scientific paper written in cooperation with Faculty of sport and physical education, which was presented at the scientific conference "Aesthetic sports between art and sport" under the title <a href="../naucnirad.docx" download class="bioLink">"Relationship od kinematic variables with expert evaluation in the basic ballet jump Grand Jete"</a>.
        <br><br>
          Choreography is my passion and I always create it based on the music that inspires me, I am not strictly bound by style, because I believe that dance is something that should not be tamed, but should be expressed as it feels.`,

        cv: "Download the CV for more information", //Biography END
        beachTitle: "Beach Sports", //Beach END
        tvTitle: "Commercials Tv Show",
        links: "Useful links",
        by: "Developed by",
        sentMessage: "The message was sent successfully. Thanks!",
        studioTitle: "Dance studio",
        studioText:
          "MAkoreografiJA is a dance studio founded and motivated by an endless love for dance, designed to make everyone who enters its premises fall in love with music, dance and exercise.MAkoreografiJA aims to, through fun and dynamic trainings led by educated trainers with decades of experience, enable the healthy development of each child, but also an environment in which each of them will be able to express their creativity, in which individuality will be nurtured, but also encourage teamwork.",
        studioText2:
          "We strive for our dance studio to be a place where children will:",
        li1: "socialize and have fun",
        li2: "learn about dance, but also about life",
        li3: "properly develop and avoid or correct postural deformities",
        li4:
          "acquire good habits that will help them in the challenges that life brings",
        li5: "build self-confidence and get rid of stage fright",
        studioText3:
          "If you want your child to be a gentle grace with the heart and discipline of a soldier, then MAkoreografiJA is the right choice for you",
        studioTextHome:
          "MAkoreografiJA is a dance studio founded and motivated by an endless love for dance, designed to make everyone who enters its premises fall in love with music, dance and exercise.",
        btnMore: "Find out more",
        studioNumber:
          "For information about appointments, you can call the number:"
      }
    },
    rs: {
      translation: {
        //Navbar start
        homeTitle: "Početna",
        beachLink: "Sportovi na plaži",
        moulinText:
          "U 2016. pobedila sam na takmičenju poznate TV emisije VICE. Nagrada je bila ispunjenje mog sna – a to je bilo da odem u Pariz i plešem rame uz rame sa plesačima Mulen Ruža. Ovo je bilo jedno životno iskustvo, koje mi je proširilo vidik i dalo mi hrabrosti da sanjam veće. Provela sam ceo dan sa plesačima Mulen Ruža, učestvovala u njihovoj probi naučila čuvenu koreografiju Can – Can.",
        moulinTitle: "Cela Emisija ",
        galleryTitle: "Galerija ", //MOULIN END
        musicTitle: "Muzika", //MUSIC END
        snTeamTitle: "Reprezentacija srbije",
        snText: "Koreograf i plesač", //SNT END
        musicalText:
          "2021. godine bila sam koreograf mjuzikla „Kralj lavova“ u BiVillage-u, Fažana (Hrvatska), takođe glavna uloga (Nala).", //MUSICAL END
        kkczText:
          "Koreograf, navijačica i kapiten tima Red Passion Fly, zvanične navijačke grupe Košarkaškog kluba Crvena zvezda u Beogradu 2014-2021.",
        kkczTitle: "Košarkaški klub Crvena zvezda", //KKCZ END
        fitnessTitle: "Fitnes Galerija",
        f4Title: "Evroliga",
        f4Text:
          "2019. Red Passion Fly je pobednik EFES plesnog izazova. Moj tim i ja smo nastupali u F4 Evroligi (Španija), a ja sam bila koreograf i igrač. Evo mojih omiljenih koreografija", //F4 END
        contactTitle: "Kontakt",
        contactText:
          "Rado ću odgovoriti na sva vaša pitanja ili vam dati procenu. Samo nam pošaljite poruku u formi ispod sa svim pitanjima koja imate.",
        labelName: "Vaše ime (obavezno)",
        labelEmail: "Vaš email (obavezno)",
        labelSubject: "Naslov",
        labelMessage: "Vaša poruka (obavezno)",
        contactBtn: "Pošaljite",
        social: "Društvene mreže", //Contact END
        biographyTitle: "Biografija",
        biographySubtitle: "PROFESIONALNI PLESAČ I KOREOGRAF",
        biographyAboutTitle: "O meni:",
        biographyText:
          'Plesom se bavim više od 20 godina, a profesionalno kao plesač i kao trener preko 10 godina. Diplomirala sam na smeru sportski ples, na Fakultetu sporta i fizičkog vaspitanja u Beogradu. <br><br> Takođe, bavim se naučnim istraživanjem uticaja primenjivanja biomehaničkih principa i metoda na plesni trening, što me čini pionirom u ovoj oblasti u Srbiji. Iza mene stoji rad <a class="bioLink" href="https://phaidrabg.bg.ac.rs/view/o:26639"  target="_blank" rel="noreferrer">"Biomehanička analiza osnovnih baletskih elemenata u funkciji unapređenja tehnike i prevencije povreda", </a> <a href="./"></a> kao i rad napisan u saradnji sa Fakultetom sporta i fizičkog vaspitanja koji je izlagani na naučnoj konferenciji "Estetski sportovi između umetnosti i sporta" pod nazivom <a href="../naucnirad.docx" download class="bioLink">"Povezanost kinematičkih varijabli sa ekspertskom ocenom kod osnovnog baletskog skoka Grand jete" </a> <br><br>Koreografija je moja strast i uvek je osmišljavam po uzoru na muziku koja me inspiriše. Ne vezujem se za stil, jer smatram da je ples nešto što ne bi trebalo da se kroti, već da se ispoljava onako kako se i oseća.',
        cv: "Preuzmite CV za više informacija", //Biography END
        beachTitle: "Sportovi na plaži", //Beach END
        tvTitle: "Reklame i tv emisije",
        links: "Korisni linkovi",
        by: "Razvijeno od strane",
        sentMessage: "Poruka je uspešno poslata. Hvala!",
        studioTitle: "Plesni studio",
        studioText: `MAkoreografiJA je plesni studio osnovan i motivisan beskrajnom ljubavlju prema plesu, osmišljen da svakog ko uđe u njegove prostorije zaljubi u muziku, ples i vežbanje. MAkoreografiJA ima za cilj da, kroz zabavne i dinamične treninge vođene od strane školovanih trenera sa decenijskim iskustvom, omogući zdrav razvoj svakog deteta, ali i ambijent u kojem će svako od njih moći da ispolji svoju kreativnost, u kojem će se negovati individualnost, ali i podsticati rad u timu. `,
        studioText2:
          "Težimo ka tome da naš plesni studio bude mesto u kojem će se deca:",
        li1: "družiti i zabavljati",
        li2: "učiti o plesu, ali i o životu",
        li3:
          "na pravilan način razvijati i izbeći ili korigovati posturalne deformitete",
        li4:
          "sticati dobre navike koje će im pomoći u izazovima koje život nosi",
        li5:
          "izgraditi samopouzdanje i osloboditi se treme prilikom scenskog nastupa.",
        studioText3:
          "Ukoliko želite da vaše dete bude nežna gracioza sa srcem i disciplinom vojnika, onda je MAkoreografiJA pravi izbor za Vas.",
        studioTextHome:
          "MAkoreografiJA je plesni studio osnovan i motivisan beskrajnom ljubavlju prema plesu, osmišljen da svakog ko uđe u njegove prostorije zaljubi u muziku, ples i vežbanje.",
        btnMore: "Saznaj više",
        studioNumber: "Za informacije o terminima možete pozvati na broj:"
      }
    }
  },
  lng: localStorage.getItem("lng") || "en"
});

export default i18next;
